/* Header */
.transition1::after,
.transition1::before,
.transition1,
.lets,
.letsArrow,
.hoverImg img,
.hoverImg {
  transition: 0.5s ease-in-out;
}

/* Hero Section */

.poly {
  height: 100vh;
  position: relative;
  /* background-image: url(../images/texture.png);     */
}
.polyPath {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.p1 {
  height: 100%;
  clip-path: polygon(0 0, 40% 0, 65% 100%, 0% 100%);
  background-image: linear-gradient(
    rgba(250, 254, 255, 0.7) 29%,
    rgba(0, 157, 174, 0.7) 83%
  );
  z-index: 1;
}
.p1 button p {
  z-index: 999;
}
.p2 {
  clip-path: polygon(100% 50%, 46% 25%, 65% 100%, 100% 100%);
  background-image: linear-gradient(
    rgba(255, 180, 58, 0) 32.71%,
    #ffb43a 99.45%
  );
}
.transition1:hover .lets {
  width: 75%;
  transform: translateX(-9px);
  color: #ffffff;
}
.transition1:hover .letsArrow {
  right: 20px;
  color: #ffffff;
}

/* Connect */

.shadow {
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}
.imgShadow {
  border: 1px solid #ececec;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
}

/* CaseStudy */

.rgbaBlack {
  background: rgba(255, 255, 255, 0.08);
}

/* Blog */

.button-bg {
  background: rgba(255, 163, 45, 0.3);
}
.hoverImg:hover img {
  transform: scale(1.05);
}

/* Footer */

/* .borderBo {
  opacity: 0.2;
  border: 1px solid linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    #fff 55.5%,
    rgba(255, 255, 255, 0) 100%
  );
} */
.spanHo li:hover span {
  color: #ffb43a;
}
.bgImage {
  background-image: url(../images/texture.png);
}

.animate {
  animation: circle 5s linear infinite;
  transition: 0.5s ease-in-out;
}

@keyframes circle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/*  */
.scrollEvent{
  overflow: scroll;
}
.scrollEvent::-webkit-scrollbar , .scrollHidden::-webkit-scrollbar{
  display: none;
}
.gridC::-webkit-scrollbar {
  display: none;
}
.afterEffect{
  position: relative;
  overflow: hidden;
  transition: 0.5s ease-in-out;
}
.afterEffect p{
  z-index: 99;
  position: relative;
}
.afterEffect::after{
  position:absolute;
  content: "";
  width:200px;
  height:200px;
  background-color: #faf5ea;
  /* right: 0; */
  left: 100%;
  top: 100%;
  bottom: 0;
  /* transform: rotate(90deg); */
  transition: 0.5s ease-in-out;
}
.afterEffect:hover::after{
  transform: rotate(-10deg);
  /* right: -40px; */
  top: 50%;
  left: 75%;
  /* transition: 0.5 ease-in-out; */

}
.gridC svg {
  fill: #5abbc0;
  /* transition: 0.5s ease-in-out; */
}
.gridC div:hover svg{
  background-color: #5abbc0;
  fill: white;
}
.gridC div:hover .pathH{
  fill: #5abbc0;
}
.gridC path {
  animation: circle 4s linear infinite;
}

@keyframes circle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-3deg);
  }
  75% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
/* .stbox{
  background-image: url(../images/StrokeBox.png);
  background-size: cover;
} */